$(document).ready(function() {

    setTimeout(function() {
        $('#loader-screen #logo-loader').fadeIn(700);
    }, 800);

    setTimeout(function() {
        $('#header-section .aos-animate').removeClass("aos-animate");
        $('#loader-screen #logo-loader').fadeOut(700, function() {
            $('#loader-screen').fadeOut(700, function() {
                $('#header-section .aos-init').addClass("aos-animate");
            });
        });
    }, 2000);

    if ($(window).width() < 768) {
        $('body').addClass('white');

        $(window).on('scroll', function() {
            var scrollTop = $(window).scrollTop();

            if (scrollTop > 35) {
                $('body').removeClass('white');
                $('header.header').css('background-color', 'white');
            } else {
                $('body').addClass('white');
                $('header.header').css('background-color', 'transparent');
            }
        });
    }

    var homeFullpage = new fullpage('#main', {
        autoScrolling: true,
        navigation: true,
        navigationPosition: 'right',
        navigationTooltips: ['01 <span class="hover">- Présentation</span>', '02 <span class="hover">- Prestations</span>', '03 <span class="hover">- Cours de bourse</span>', '04 <span class="hover">- Nos derniers biens</span>', '05 <span class="hover">- Médiathèque</span>', '06 <span class="hover">- Contact</span>'],
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        slidesNavigation: true,
        slidesNavPosition: 'bottom',
        animateAnchor: false,
        responsiveWidth: 768,
        afterLoad: function (origin, destination, direction) {
            if ($(window).width() > 767) {
                if (destination.anchor == "" || destination.anchor == "services" || destination.anchor == "medias") {
                    $('body').addClass('white');
                    $('body').removeClass('menu-white');
                    $('body').removeClass('discover-white');
                } else if (destination.anchor == "contact") {
                    $('body').removeClass('white');
                    $('body').addClass('menu-white');
                    $('body').addClass('discover-white');
                } else {
                    $('body').removeClass('white');
                    $('body').removeClass('menu-white');
                    $('body').removeClass('discover-white');
                }
            }

            if (destination.anchor == "") {
                $('header.header > nav.navbar > .mx-auto').hide();
            } else {
                $('header.header > nav.navbar > .mx-auto').show();
            }

            if (destination.anchor == "proprietes") {
                setInterval(function () {
                    homeFullpage.moveSlideRight();
                }, 10000);
            }

            $('.section.active [data-aos]').each(function () {
                $(this).addClass("aos-animate")
            });
        },
        onLeave: function(){
            $('.section [data-aos]').each(function(){
                $(this).removeClass("aos-animate")
            });
        },
        onSlideLeave: function(){
            $('.slide [data-aos]').each(function(){
                $(this).removeClass("aos-animate")
            });
        },
        afterSlideLoad: function(){
            $('.slide.active [data-aos]').each(function(){
                $(this).addClass("aos-animate")
            });
        }
    });


    $('[data-aos]').each(function(){ $(this).addClass("aos-init"); });

    $('#carousel-medias').owlCarousel({
        items: 1,
        nav: true,
        navText: ['<img src="/wp-content/themes/tiz-wmp/assets/img/layout/long-arrow-horizontal-left-white.png" />', '<img src="/wp-content/themes/tiz-wmp/assets/img/layout/long-arrow-horizontal-white.png" />'],
        dots: false,
        margin: 50,
        stagePadding: 500,
        center: true,
        video:true,
        lazyLoad:true,
        responsive: {
            0: {
                items: 1,
                stagePadding: 0
            },
            768: {
                items: 1,
                stagePadding: 100
            },
            992: {
                items: 1,
                stagePadding: 200
            },
            1201: {
                items: 1,
                stagePadding: 300
            },
            1441: {
                items: 1,
                stagePadding: 500
            }
        }
    });

    $('#list-services').owlCarousel({
        items: 3,
        nav: true,
        navText: ['<img src="/wp-content/themes/tiz-wmp/assets/svg/layout/carousel-arrow-left.svg" />', '<img src="/wp-content/themes/tiz-wmp/assets/svg/layout/carousel-arrow-right.svg" />'],
        dots: false,
        margin: 15,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            }
        }
    });


    $(document).on('click', '#contact-section .open-footer', function() {
        if ($(this).hasClass('up')) {
            $(this).removeClass('up');
            $('footer#footer').removeClass('up');
        } else {
            $(this).addClass('up');
            $('footer#footer').addClass('up');
        }
        return false;
    });
});